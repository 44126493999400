@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Allerta+Stencil&family=Josefin+Sans:wght@500&family=Paytone+One&family=Saira:wght@500&family=Exo+2:wght@300&family=Alfa+Slab+One&family=Source+Serif+Pro:wght@300&family=Tilt+Warp&family=Titan+One&display=swap');

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  transition-duration: "3000ms";
}


.image {
  @apply w-12 h-12 object-contain px-1.5 pointer-events-none;
}

.list {
  @apply font-source;
}

.anchor {
  @apply text-purp  font-teko font-light  px-1 hover:font-black text-xl;
}
.header {
  @apply font-titan text-center text-3xl text-gray-800;
}
.input,
textarea {
  @apply w-full p-2.5 my-1.5 border-b border-black font-teko font-black outline-none text-xl bg-gray-200;
}
.inputBase{
  @apply w-full p-2.5 mt-10 border-b border-black font-teko font-black outline-none text-xl bg-gray-200;
}
.navList {
  @apply text-black font-bold text-sm pl-0 md:pl-10 no-underline hover:text-purp hover:font-black ;
}
.paragraph{
    @apply my-3 text-sm 
}

.button{
  @apply m-2 px-4 border border-black rounded-xl font-chakra font-bold hover:bg-newColor hover:text-white hover:border-newColor duration-500
}

.error{
  @apply text-sm text-red-500 text-right
}
